



















// import { Component, Vue } from 'vue-property-decorator'
import Vue from 'vue'
import { State, Action, Getter } from 'vuex-class'
import Component from 'vue-class-component'
// import { ProfileState, User } from '@/store/profile/types'
import { ProfileState } from '@/store/profile/types'
// test eslint-disable-next-line
import { Auth, Hub } from 'aws-amplify'
import bulletTrain from 'bullet-train-client'

const namespace: string = 'profile'

@Component
export default class Authentication extends Vue {
  private showDebug: boolean = false;
  private bulletTrain = bulletTrain.init({ environmentID: 'd2mHvsVzLfiNNBkq9YCSij', onChange: this.featureFlagChanged })

  /* eslint-disable @typescript-eslint/no-explicit-any */
  @State('profile')
  profile!: ProfileState;

  @Action('ssoLogin', { namespace })
  ssoLogin: any;

  @Action('ssoLoggedIn', { namespace })
  ssoLoggedIn: any;

  @Action('logout', { namespace })
  logout: any;

  @Getter('loggedIn', { namespace })
  loggedIn!: boolean
  /* eslint-enable @typescript-eslint/no-explicit-any */

  private featureFlagChanged () {
    this.showDebug = bulletTrain.hasFeature('show-debug')
  }

  mounted () {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      console.log('auth', event, data)

      switch (event) {
        case 'signIn':
          this.ssoLoggedIn(data)
          break
      }
    })
  }

  testStateIn () {
    this.ssoLoggedIn()
  }

  testStateOut () {
    this.logout()
  }
}
